import React from 'react';
import { Link } from "react-scroll";
import Typed from "react-typed";
import AOS from 'aos';
import "../assets/custom.css";

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const hero = () => {
    return(
        <div className="v-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h6 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >Welcome to my website
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big mb-0">Hi, I'm <span className="color">Edward Muss</span></div>
                        <div className="h1_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <Typed
                                strings={[ "an Engineer.", "a Developer. ", "a Programmer." , "a Photographer.",  "Graphic Designer."]}
                                typeSpeed={60}
                                backSpeed={50}
                                loop
                              />
                        </div>
                        <p className="list_location"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                           I am a Mechanical Engineer, a Fullstack Developer, Mobile Apps Developer, Graphic Designer, Video Editor, Photographer, Saxophonist, Guitarist, I break down complex concepts by showing you how to implement them in project-based courses and tutorials. I love coding, yes I do it for fun sometimes 😂
                        </p>
                        <button className="btn-main mt-3 my-resume">
                            <Link className="nav-link transition" spy to="resume">
                                My Resume
                            </Link>
                        </button>
                    </div>

                    <div className="col-md-6">
                        <img src="./img/misc/1.png" className="img-fluid" alt="imghero"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default hero;