import React , { useEffect, useState } from 'react';

const Footer = () => {

    const [userdetails, setUserDetails] = useState([])
    const fetchUserData = () => {
        fetch("https://edwardmuss.tech/admin/api/userdetails")
        .then(response => {
            return response.json()
        })
        .then(data => {
			setUserDetails(data)
        })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.edwardmuss.tech" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright {new Date().getFullYear() } - {userdetails.fullname}</span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                            <a href={userdetails.facebook} target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            <a href={userdetails.instagram} target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                            <a href={userdetails.linkedin} target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                            <a href={userdetails.youtube} target="_blank" rel="noreferrer"><i className="fa fa-youtube"></i></a>
                            <a href="https://www.tiktok.com/@__edwardmuss__" target="_blank" rel="noreferrer"><i className="fa fa-tiktok"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;