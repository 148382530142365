import React , { useEffect, useState } from 'react';
import AOS from 'aos';
AOS.init();



const Hero = () => {
    const [experiences, setExperiences] = useState([])
    const [education, setEducation] = useState([])
    const [items, setItems] = useState(3)
    const [userdetails, setUserDetails] = useState([])

    const fetchExperienceData = () => {
        fetch("https://edwardmuss.tech/admin/api/experiences")
        .then(response => {
            return response.json()
        })
        .then(data => {
            setExperiences(data)
        })
    }

    const fetchEducationData = () => {
        fetch("https://edwardmuss.tech/admin/api/education")
        .then(response => {
            return response.json()
        })
        .then(data => {
            setEducation(data)
        })
    }

    const fetchUserData = () => {
        fetch("https://edwardmuss.tech/admin/api/userdetails")
        .then(response => {
            return response.json()
        })
        .then(data => {
			setUserDetails(data)
        })
    }

    const loadMore = () => {
		setItems(items + items)
	}

	const loadLess = () => {
		setItems(3)
	}

    useEffect(() => {
        fetchExperienceData()
        fetchEducationData()
        fetchUserData()
    }, [])

    const slice = experiences.slice(0, items);
	const slicelength = slice.length
    const slice_edu = education.slice(0, items);
	const slice_edu_length = slice_edu.length

	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
                    <a href={userdetails.cv_link} target="_blank" rel="noreferrer" className="btn-main mt-0">Download CV</a>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences</h3>
                        {experiences.length > 0 && (
                        <ul className="d_timeline">
                            {slice.map(experience => (
           
                            <li key={experience.id} className="d_timeline-item">
                                <h3 className="d_timeline-title">{ experience.start_date.substring(0, 4) } - { new Date(experience.end_date) > new Date() ? 'Now' : experience.end_date.substring(0, 4) }</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">{ experience.job_title }</span>
                                	<span className="d_company">{experience.employer}</span>
                                	<div dangerouslySetInnerHTML={{ __html: experience.description }} />
                                </p>
                            </li>
                            ))}
                        </ul>
                        )}
                        { experiences.length > 3 && experiences.length !== slicelength ?
                            <button type='button' id='' className="btn-main text-center" onClick={() => loadMore()}>
                                Load More
                            </button>
                            : '' }

                            { experiences.length === slicelength && experiences.length > 3?
                            <button type='button' id='' className="btn-main text-center" onClick={() => loadLess()}>
                                Collapse
                            </button>
					: '' }
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        {education.length > 0 && (
                        <ul className="d_timeline">
                            {slice_edu.map(edu => (
           
                            <li key={edu.id} className="d_timeline-item">
                                <h3 className="d_timeline-title">{ edu.graduation_start_date.substring(0, 4) } - { new Date(edu.graduation_end_date) > new Date() ? 'Now' : edu.graduation_end_date.substring(0, 4) }</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">{ edu.degree }</span>
                                	<span className="d_company">{edu.school_name}</span>
                                	<div dangerouslySetInnerHTML={{ __html: edu.description }} />
                                </p>
                            </li>
                            ))}
                        </ul>
                        )}
                        { education.length > 3 && education.length !== slice_edu_length ?
                            <button type='button' id='' className="btn-main text-center" onClick={() => loadMore()}>
                                Load More
                            </button>
                            : '' }

                            { education.length === slice_edu_length && education.length > 3?
                            <button type='button' id='' className="btn-main text-center" onClick={() => loadLess()}>
                                Collapse
                            </button>
					: '' }
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default Hero;