import React , { useEffect, useState } from 'react';
import Skillbar from '../layout/skillbar';

import AOS from 'aos';
AOS.init();


const Hero = () => {

	const [skills, setSkills] = useState([])
	const [userdetails, setUserDetails] = useState([])
	const [items, setItems] = useState(5)

    const fetchUserSkils = () => {
        fetch("https://edwardmuss.tech/admin/api/skills")
        .then(response => {
            return response.json()
        })
        .then(skills_data => {
            setSkills(skills_data)
        })
    }

    const fetchUserData = () => {
        fetch("https://edwardmuss.tech/admin/api/userdetails")
        .then(response => {
            return response.json()
        })
        .then(data => {
			setUserDetails(data)
        })
    }

    useEffect(() => {
        fetchUserSkils()
        fetchUserData()
    }, [])

	const loadMore = () => {
		setItems(items + items)
	}

	const loadLess = () => {
		setItems(5)
	}

	const slice = skills.slice(0, items);
	const slicelength = slice.length

	return(
		<div className="v-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6">
                    <img src="./img/misc/4_1.png" className="img-fluid" alt=""/>
                </div>
	            <div className="col-md-6">
	                <h2>About {userdetails.fullname}</h2>
					<div dangerouslySetInnerHTML={{ __html: userdetails.summary }} />
					{ slice.length > 0 && (
	                <div className="d-skills-bar">
	                  <div className="d-bar">
					  {slice.map(skill => (
	                    <div key={skill.id} className="d-skill">
	                        <div className="d-info">
	                          <span>{skill.name}</span>
	                        </div>
	                        <Skillbar bgColor={"#f3aa4e"} progress={skill.rating} />
	                    </div>
						))}

	                    </div>
	                </div>
					)}
					{ skills.length > 5 && skills.length !== slicelength ?
					<button type='button' id='' className="btn-main" onClick={() => loadMore()}>
						 Load More
					</button>
					: '' }

					{ skills.length === slicelength ?
					<button type='button' id='' className="btn-main" onClick={() => loadLess()}>
						 Collapse
					</button>
					: '' }
	            </div>  

			</div>
		</div>
		</div>
	);
}

export default Hero;